<!-- Settings -->
<template>   
  <div >
      <div slot="header" class="modal-header popup-header">
            <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}<b> - {{ this.sku }}</b></span>
            <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
        </div>

  <div class="card card-custom product_ct" v-loading="loading">
    <div class="card-body">
      <div class="row">
          <div class="col-sm-9">
          <div class="d-flex mb-9">		   
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                  <span class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">{{view.name}}</span>
                  <span v-if="view.record_status ==1"><i class="flaticon2-correct text-success font-size-h5"></i></span>
              </div>				
          </div>
          <div class="d-flex flex-wrap justify-content-between mt-1">
            <div class="d-flex flex-column flex-grow-1 pr-8">
              <div class="d-flex flex-wrap mb-4">
                  <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-layers-2 mr-2 font-size-lg"></i>SKU : <b>{{view.sku}}</b></span>
                  <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Brand : <b>{{view.brand_model}}</b></span>            
                  <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list-2 mr-2 font-size-lg"></i>Category : <b>{{view.product_category ? view.product_category.label:''}}</b></span> 
                  <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-cube mr-2 font-size-lg"></i>Unit Type : <b>{{view.unit ? view.unit:''}}</b></span>                               
              </div>                    
            </div>				
          </div>
        </div>
      </div> 
      </div>
      <div class="col-sm-3">
          <!-- <div class="text-right"> 
            <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="editProduct(view.id)"><i class="fa fa-pen"></i>Edit
            </b-button>
          </div> -->
        </div>
      </div>   
    <el-form>
      <div class="row" >
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <h4>Product Description</h4>
                    </div>
                </div>            
                <div class="row">
                  <div class="col-xl-12">
                      <span class="font-weight-bold text-dark">{{view.description}}</span> 
                  </div>
                </div>
            </div>
      </div>   
         <hr/>
         <div class="row mt-9">
            <div class="col-sm-5">
              <div class="row">
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-hexagonal"></i>weight</label>
                    <p v-if="view.weight">{{view.weight != null ? view.weight : '-'}} Kg</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-line"></i>Length</label>
                    <p v-if="view.length">{{(view.length) ? view.length : '-'}} inch</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-size"></i>Width</label>
                    <p v-if="view.width">{{view.width}} inch</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-arrow-1"></i>Height</label>
                    <p v-if="view.height">{{view.height}} inch</p>
                  </div>
                </div>              
              </div>
            </div>
         </div>
         <hr/>
         
    <div class="row mt-7" >
        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Price Details</h4>
                </div>
            </div>            
            <div class="row">
                <div class="col-sm-12">
                  	<div class="d-flex flex-wrap justify-content-between mt-1">
                      <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-layers-2 mr-2 font-size-lg"></i><span class="font-size-h4 font-weight-bolder">Square Feet : {{view.sq_feet}}</span>(sq.ft)</span>                      
                         
                        </div>                        
                        <div class="d-flex flex-wrap mb-4" v-for="(input,k) in supplier" :key="k">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-rotate mr-2 font-size-lg"></i>Supplier {{(k+1)}} : {{input.supplier_name?input.supplier_name:null}}</span>                       
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Supplier ID {{(k+1)}} : {{input.supplier_id}}</span> 
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Supplier SKU : {{input.mfg_id}}</span> 
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Supplier MFG ID : {{input.supplier_mfg_id}}</span>                           
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Supplier UPC : {{input.upc_code}}</span>                           
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Supplier Cost {{(k+1)}} : ${{input.supplier_cost?parseFloat(input.supplier_cost).toFixed(2):'0.00'}}</span>                                             
                        </div> 
                        <div class="d-flex flex-wrap mb-4">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i><span class="font-size-h4 font-weight-bolder"> Cost Price : ${{ view.cost_price?parseFloat(view.cost_price).toFixed(2):'0.00'}}</span></span>    
                        </div>     
                        <div class="d-flex flex-wrap mb-4">
                           <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Regular Discount off:<span v-if="view.regular_per">{{view.regular_per}} %</span></span>
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Regular Price: ${{view.regular_price?parseFloat(view.regular_price).toFixed(2):'0.00'}}</span>
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Special Price: ${{view.special_price?parseFloat(view.special_price).toFixed(2):'0.00'}}</span>
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Inside Price: <span v-if="view.inside_per">{{view.inside_per}} %</span></span>
                         
                        </div>     
                      </div>                   
                    </div> 
                </div>   
          </div>
        </div>       
    </div>
    <div class="row mt-7">      
           <div class="col-xl-4">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Contractor</h4>
                </div>                
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Discount Off: {{view.contractor_discount}} %</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Contractor Sale Price: ${{view.contractor_price?parseFloat(view.contractor_price).toFixed(2):'0.00'}}</span>
                                    
                        </div>
                    </div>
                </div>
              </div>                             
            </div>
        </div>
        <div class="col-xl-4">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Dealer</h4>
                </div>                
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Dealer Margin: {{view.dealer_discount}} %</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Dealer Sale Price: ${{view.dealer_amount?parseFloat(view.dealer_amount).toFixed(2):'0.00'}}</span>
                                    
                        </div>
                    </div>
                </div>
              </div>                             
            </div>
        </div>
         <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-12">
                    <h4>Whole Sale Details</h4>
                </div>                
            </div>
             <div class="row">
                <div class="col-xl-12">
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Discount Off : {{view.whole_discount}} %</span>                       
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Whole Sale Price : ${{view.whole_amount?parseFloat(view.whole_amount).toFixed(2):'0.00'}}</span>  
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Minimum Amount : ${{view.whole_min_amount?parseFloat(view.whole_min_amount).toFixed(2):'0.00'}}</span>                                                   
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-coins mr-2 font-size-lg"></i>Minimum Quantity : {{view.whole_quantity?view.whole_quantity:0}}</span>                                                                                                    
                        </div>
                    </div>
                  </div>
                </div>
             </div>
           </div>      
    </div>
      <div class="row mt-7 ">      
        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Inventory Details</h4>
                </div>                
            </div>
            <div class="row">
                <div class="col-xl-12">
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>In Stock : {{view.stock_quantity?view.stock_quantity:0}}</span>                       
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Reorder : {{view.reorder?view.reorder:0}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Min Qty : {{view.min_quantity?view.min_quantity:0}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Max Qty : {{view.max_quantity?view.max_quantity:0}}</span>
                             <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" v-if="view.stock_quantity > view.max_quantity"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Over Stock Qty : {{view.stock_quantity > view.max_quantity ? view.stock_quantity - view.max_quantity:0}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                              <i class="flaticon-interface-9 mr-2 font-size-lg"></i>
                              Stock Status : <span v-if="view.stock_quantity >0" class="active font-size-h4 font-weight-bolder">In Stock</span> 
                                             <span v-else class="red font-size-h4 font-weight-bolder"> Out Of Stock</span> 
                            </span>
                        </div>           
                                                   
                    </div>				
                  </div>
                </div>  
            </div>
        </div>       
                  
        <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <h4>Product Details</h4>
                    </div>
                </div>               
                <div class="row">
                   <div class="col-xl-6"> 
                      <div class="d-flex flex-wrap justify-content-between mt-1">
                            <div class="d-flex flex-column flex-grow-1 pr-8">
                                <div class="d-flex flex-wrap mb-4" v-if="view.inventory_prod ==='1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Inventory product</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.service_req === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Service required</span>                       
                                </div>
                                <div class="d-flex flex-wrap mb-4" v-if="view.reg_customer === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Registered customer</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.ask_serial === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Ask serial number when sold</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.refund_exchange === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Refundable / Exchangeable</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.is_gift === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Is a gift Card</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.clearance_product === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Clearance Product</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.catalogue_product === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Available in Catalogue</span>                       
                                </div>
                            </div>
                      </div>  
                   </div>                  
                   <div class="col-xl-12">  
                       <div class="d-flex flex-wrap justify-content-between mt-1">
                            <div class="d-flex flex-column flex-grow-1 pr-8">
                                <div class="d-flex flex-wrap mb-4"  v-for="(input,k) in view.mgf_list" :key="k">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>MGF Model Number {{(k+1)}}: {{input.mgf_model}}</span> 
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>MGF Serial Number {{(k+1)}}: {{input.serial_number}}</span>                                             
                                </div>                                                            
                            </div>
                       </div>   
                    </div>
                                                 
                </div>          
            </div>
             <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <h4>Image Gallery</h4>
                    </div>
                </div>               
                <div class="row">
                   <div class="col-xl-12">
                      <span class="flex-shrink-0 mr-7 mt-lg-0 mt-3"  v-for="(input,k) in images" :key="k">
                        <div class="symbol symbol-50 symbol-lg-120 index-image employee-image">
                        <img class="" :src="input.url">        
                        </div>			
                      </span> 
                   </div>
                </div>
             </div>            
        </div>
    </el-form>
   </div>
 </div> 
 </div>        
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getSimilarProduct,getProductImage} from "@/api/product";

export default {
  name: 'view-product',
  props: {
    sku: {
	      	type: String,
	      	required: true
	    },
      label:{
        type: Object,
        default() {
          return {title:'View Product'}
        }
      }
  },
   data() {
    return {
      loading: false,
      img_url : process.env.VUE_APP_BASE_IMAGE_URL+'/products',
      view:[],
      title_text: 'Product Details',
      supplier:[],
      images: [],

    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Product List", route: "/product/list" },
      { title: "View Product" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
    closeForm() {
      
        this.$emit('close')
      
    },

  fetchData() {
      this.loading = true
      getSimilarProduct(this.sku).then(response => {
        this.view = response.data.data
         getProductImage(this.sku).then(response => {       
          this.images = response.data.data
          this.loading = false          
      });   
        this.supplier = response.data.data.supplier_list;
        this.loading = false
      });
    },
    editProduct(id){
    this.$router.push({path:'/product/edit/'+id})
    },
    getsimilarProduct(similar_product){
      const Product_sku = similar_product.split(',');
      let similar_sku = [];
      Product_sku.map((prod_sku)=>{
       
        similar_sku.push(prod_sku);
      })
      return similar_sku;
    },
    viewProduct(product_id) {
      this.$router.push({ path: "/product/view/" + product_id });
    },
   }
   
};
</script>
<style scoped>
.el-form h4:before {
    content: "";
    height: 2px;
    width: 60px;
    background: #3699FF;
    position: absolute;
    bottom: -1.25px;
    z-index: 1;
}

</style>